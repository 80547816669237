import { createFeature, createReducer, on } from '@ngrx/store';
import { Agent } from 'src/app/shared/models';
import { AgentActions } from './agent.actions';

export const agentFeatureKey = 'agent';

export interface AgentState {
  agents: Agent[];
  agent: Agent | null;
  loading: boolean;
  error: any;
}

export const initialState: AgentState = {
  agents: [],
  agent: null,
  loading: false,
  error: null,
};

export const reducer = createReducer(
  initialState,
  on(
    AgentActions.loadAgents,
    (state): AgentState => ({
      ...state,
      loading: true,
      error: null,
    })
  ),
  on(
    AgentActions.loadAgentsSuccess,
    (state, { agents }): AgentState => ({
      ...state,
      agents,
      loading: false,
      error: null,
    })
  ),
  on(
    AgentActions.loadAgentsFailure,
    (state, { error }): AgentState => ({
      ...state,
      loading: false,
      error,
    })
  ),
  on(
    AgentActions.loadAgent,
    (state): AgentState => ({
      ...state,
      loading: true,
      error: null,
    })
  ),
  on(
    AgentActions.loadAgentSuccess,
    (state, { agent }): AgentState => ({
      ...state,
      agents: [agent],
      agent: agent,
      loading: false,
      error: null,
    })
  ),
  on(
    AgentActions.loadAgentFailure,
    (state, { error }): AgentState => ({
      ...state,
      loading: false,
      error,
    })
  ),
  on(
    AgentActions.addAgent,
    (state): AgentState => ({
      ...state,
      loading: true,
      error: null,
    })
  ),
  on(
    AgentActions.addAgentSuccess,
    (state, { agent }): AgentState => ({
      ...state,

      agents: [...state.agents, agent],

      loading: false,

      error: null,
    })
  ),
  on(
    AgentActions.addAgentFailure,
    (state, { error }): AgentState => ({
      ...state,
      loading: false,
      error,
    })
  ),
  on(
    AgentActions.removeAgent,
    (state): AgentState => ({
      ...state,
      loading: true,
      error: null,
    })
  ),
  on(
    AgentActions.removeAgentSuccess,
    (state, { agentId }): AgentState => ({
      ...state,
      agents: state.agents.filter(agent => agent.id !== agentId),
      loading: false,
      error: null,
    })
  ),
  on(
    AgentActions.removeAgentFailure,
    (state, { error }): AgentState => ({
      ...state,
      loading: false,
      error,
    })
  ),
  on(
    AgentActions.updateAgent,
    (state): AgentState => ({
      ...state,
      loading: true,
      error: null,
    })
  ),
  on(
    AgentActions.updateAgentSuccess,
    (state, { agent }): AgentState => ({
      ...state,
      agents: state.agents.map(item =>
        // item.id === agent.id ? agent : item
        item.id === agent.id ? { ...item, ...agent } : item
      ),
      loading: false,
      error: null,
    })
  ),
  on(
    AgentActions.updateAgentFailure,
    (state, { error }): AgentState => ({
      ...state,
      loading: false,
      error,
    })
  )
);

export const agentFeature = createFeature({
  name: agentFeatureKey,
  reducer,
});
